import React from 'react'
import CubeMobile from '../CubeMobile/CubeMobile';
import "./cotyKPIs.css"; 

function CotyKPIS() {
  return (
    <>
    <div className='cotyKPIs'>
            <h1 className='firstChar'>COTY</h1>
            <h1 className='secondChar'>KPIS</h1>
        </div>
        <div>
        <CubeMobile
            front={
                  <div className='sideCubeFont'></div>
              }
              back={
                <div className='sideCubeFont'></div>
             }
              left={
                <div className='sideCubeFont'></div>
              }
              right={
                <div className='sideCubeFont'></div>
              }
              top={
                <div className='sideCubeFont'></div>
             }
              bottom={
                <div className='sideCubeFont'></div>
             }
        
        />
        

        </div>
    </>
  )
}

export default CotyKPIS