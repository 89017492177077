import React, { useEffect } from 'react'
import NewSideCube from '../Cube/NewSideCube';
import { Box } from "@mui/material";
import DownloadingIcon from '@mui/icons-material/Downloading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

//Need to update the url string below if redirect url has changed looks like always using staging

function AwsHosted() {
    const host = window.location.hostname
    useEffect(() => {

          // 👇️ redirects to an external URL
          window.location.replace('https://metrics-staging.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=7bmck69dua2615s5c5fprff9hu&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2F' + host + '%2Fhome');
          
    

      }, [host]);
    
      return <div className='load'>
      <NewSideCube
       front={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       back={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       left={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       right={
         <Box sx={{ p: 2 }}>
           <div className='sideCubeFont'>LOADING...</div>
           <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
         </Box>
       }
       top={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
       bottom={
        <Box sx={{ p: 2 }}>
          <div className='sideCubeFont'>LOADING...</div>
          <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
        </Box>
      }
     />
    </div>;
    
}

export default AwsHosted