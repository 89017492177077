import Home from "./pages/Home/Home";
import {  Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import AwsHosted from "./components/AwsHosted/AwsHosted";
import React from "react";
import OnePage from "./components/OnePage/OnePage";
import KPIMobileBeta from "./pages/KPIMobileBeta/KPIMobileBeta";



//import {ReactQueryDevtools} from "@tanstack/react-query-devtools"


const queryClient = new QueryClient()

function App() {

  
  return (
    <QueryClientProvider client = {queryClient}>
      <Routes>
      <Route path="/login" element={<AwsHosted/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/main" element={<Main/>} />
      <Route path = "/one" element = {<OnePage/>}/>
      <Route path = "/mobileBeta" element = {<KPIMobileBeta/>}/>
    </Routes>
    </QueryClientProvider>
      
    
  )
}

export default App;
