import React from 'react'
import './footer.css'
import CopyrightIcon from '@mui/icons-material/Copyright';

function Footer() {
  const date = new Date();
    let year = date.getFullYear()
  return (
    <div className='footer'>
        <CopyrightIcon style={{fontSize: "clamp(0.5rem, 0.7vw, 3rem)"}}/>
        <div>
        <h5>{year}, Coty Inc.
All trademarks registered. 
All rights reserved.</h5>

        </div>
        
        </div>
  )
}

export default Footer