import React from 'react'
import MetricLoader from '../metricLoader/MetricLoader'
import NewResults from '../results/NewResults'


function Feature({page, plant, user, metricCounter, rcaCounter, access, token}) {
    const countMetrics = (value) => {
      metricCounter(value)
   } 
   const countRCAs = (value) => {
    rcaCounter(value)
 } 
   
  
   
   


if (page === 'METRICS'){
    return (
        <MetricLoader access = {access} plant = {plant} user = {user} countMetrics = {countMetrics} countRCAs = {countRCAs} page = {page} token = {token}/>
      )

}
if (page === 'RESULTS'){
    return (
        <NewResults access={access} plant = {plant} countMetrics = {countMetrics} countRCAs = {countRCAs}/>
      )
}


}

export default Feature