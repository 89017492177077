import React from 'react'
import PlantOptions from '../plantOptions/PlantOptions'
import './sidebar.css'






function SideBar({callBack, plantPick, user, plant, metricCount, rcaCount}) {
  
   console.log("user:", user, "plant", plant)

 const getPlantSelection = (value) => {
    plantPick(value)
 } 
  return (
    <div className="sideBar">
      
        <div className='different'>
        <PlantOptions plant = {plant} callBackPlant = {getPlantSelection} user = {user}/>
        
          </div>
          
    </div>
  )
}

export default SideBar