import React from "react";
import "./sideCube.css";

const NewSideCube = ({ front, back, left, right, top, bottom }) => {
  return (
    <div className="side-cube-container">
      <div className="side-cube">
        <div className="side-cube-face side-cube-face-front">{front}</div>
        <div className="side-cube-face side-cube-face-back">{back}</div>
        <div className="side-cube-face side-cube-face-left">{left}</div>
        <div className="side-cube-face side-cube-face-right">{right}</div>
        <div className="side-cube-face side-cube-face-top">{top}</div>
        <div className="side-cube-face side-cube-face-bottom">{bottom}</div>
      </div>
    </div>
  );
};

export default NewSideCube;