import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import './home.css'
import NavBar from '../../components/navBar/NavBar'
import DatasetIcon from '@mui/icons-material/Dataset';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useState } from 'react';
import Footer from '../../components/footer/Footer'
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs'
import Cube from '../../components/Cube/Cube'
import { Box } from "@mui/material";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import cotyLogo from '../../img/Coty_logo.svg.png'



function Home() { 
  const host = window.location.hostname
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code')


  if(code === undefined || code === "" || code === null){
    window.location.replace('https://metrics-staging.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=7bmck69dua2615s5c5fprff9hu&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2F' + host + '%2F');
  }

  const [started, setStarted] = useState('no')
  const showPlant = () =>{
    setStarted('Yes')
  }

  const [token, setToken] = useState("")
  const [user, setUser] = useState("No User Info")

//Need to update redirect uri below if redirect url has changed...also need to update in AWS as the new callback URL 
  useEffect(() => {
    const sendAWS = async()=>{
      const postAWS = await axios.post(`https://metrics-staging.auth.us-east-1.amazoncognito.com/oauth2/token`, qs.stringify({"client_id": '7bmck69dua2615s5c5fprff9hu',
    "grant_type": 'authorization_code',
    "redirect_uri": "https://" + host + "/",
    "code": code}), {
        headers:{
          "Content-Type" : "application/x-www-form-urlencoded"
        }
      });

      console.log("response", postAWS)
      setToken(postAWS)
      return postAWS

}

sendAWS()}, [code, host])
    
  console.log("token", token)
  if (token !== "" & user === "No User Info"){
    const token1 = token.data.id_token
    const token2 = token.data.access_token
    const decode1 = jwt_decode(token1)
    const decode2 = jwt_decode(token2)
    setUser(decode1.email)
    window.sessionStorage.setItem("user", decode1.email)
    window.sessionStorage.setItem("access", decode2.client_id)
    document.cookie= `id_token = ${token.data.id_token}; secure; SameSite=none; domain = .coty.com`
    document.cookie = `access_token = ${token.data.access_token}; secure; SameSite=none; domain = .coty.com`
    document.cookie =  `refresh_token = ${token.data.refresh_token}; secure; SameSite=none; domain = .coty.com`
  }

  if(code !== undefined && code !== null && code !== ""){
    return (
      <>
      <NavBar/>
      <div className="homeFlex">
    
        
      </div>
      
      <div className="mainFlex">
    <img className = "logo" src = {cotyLogo} alt = "coty logo"/>
    </div>
      <div className="mainFlex">
      <h1>GLOBAL KPI METRICS PORTAL</h1>
      </div>
      <div className='plantPick' style={{display: started === 'Yes'? 'flex' : 'none'}}>
      <h4>Welcome, {user}</h4>
      </div>
      <div className="mainFlex">
      <button onClick={showPlant} style={{display: started === 'Yes'? 'none' : 'flex'}}>GET STARTED</button>
      </div>
      <div className="mainFlex">
        <Link className='linkFlex' to = "/main">
        <button style={{display: started === 'Yes'? 'flex' : 'none'}}>NEXT</button>
        </Link>
      
      </div>
      <div className="homeFlex2">
       <Cube
       front={
         <Box sx={{ p: 2 }}>
           <div className='cubeFont'>Actionable Data</div>
           <DatasetIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
         </Box>
       }
       back={
        <Box sx={{ p: 2 }}>
          <div className='cubeFont'>Fast Processing</div>
          <SpeedIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
        </Box>
      }
       left={
         <Box sx={{ p: 2 }}>
           <div className='cubeFont'>Optimal Decisions</div>
           <PendingActionsIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
         </Box>
       }
       right={
         <Box sx={{ p: 2 }}>
           <div className='cubeFont'>Increased Efficiency</div>
           <ThumbUpOffAltIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
         </Box>
       }
       top={
        <Box sx={{ p: 2 }}>
          <div className='cubeFont'>Real Time Results</div>
          <AccessTimeIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
        </Box>
      }
       bottom={
        <Box sx={{ p: 2 }}>
          <div className='cubeFont'>Easy to Use</div>
          <ElectricBoltIcon sx={{ fontSize: 'clamp(1rem, 1.5vw, 10rem)' }} />
        </Box>
      }
      
     />
      
      </div>
     
      <Footer/>
      </>
    )
  }
  else{
    return <div>
      
    </div>
  }
    
  }
  

export default Home