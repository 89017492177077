import React, { useState } from 'react'
import './navBar.css'
import { Link } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import { Keyboard } from '@mui/icons-material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CloseIcon from '@mui/icons-material/Close';
import PlantOptionsMobile from '../plantOptions/PlantOptionsMobile';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';


function NavBar({user, callBack, plantPick, plant, metricCount, rcaCount}) {
  const [navClick, setNavClick] = useState('no')
  const [mobile, setMobile] = useState('no')
  function dispNav(){
    if(navClick === 'no'){
      setNavClick('yes')
      setMobile('yes')
    }
    else{
      setNavClick('no')
      setMobile('yes')
    }
    
  }
  const [currPage, setCurrPage] = useState('METRICS')

 const getPlantSelection = (value) => {
    plantPick(value)
 } 
  function changePage(page){
    setCurrPage(page)
  }
  console.log(currPage)
  return (
    <div className="navFlex">
      <div className='hamburger'>
          <MenuIcon style={{fontSize:"5vw"}} onClick={dispNav}/>
          <div className='overlayNav' style={{display: navClick==='yes'? 'block': 'none'}}>
          <div className=''>
            <div className='close' onClick={dispNav}>
            <CloseIcon/>
            </div>
            
        <PlantOptionsMobile callBackPlant = {getPlantSelection} user = {user} mobile = {mobile}/>
        <div className='optionPage' onClick={()=>{changePage('METRICS'); callBack('METRICS')}}>
          <h5>Enter Metrics</h5>
          <Keyboard/>
        </div>
        <div className='optionPage' onClick={()=>{changePage('RESULTS'); callBack('RESULTS')}}>
        <h5 >View Results</h5>
          <PublishedWithChangesIcon/>
        </div>
        <h5 style={{display: plant==="none"? "none":"flex"}}>Missing Metrics for {plant}: {metricCount}</h5>
          <h5 style={{display: plant==="none"? "none":"flex"}}>Missing RCAs for {plant}: {rcaCount}</h5>
        
          </div>

        </div>

        </div>
        
        <div className="logoContain">
          
        <Link className='linkFlexNavi' to = "/">
            <h1>C O T Y</h1>
            </Link>
        </div>
        

        <div className='help' >
          <div className='welcomeUser'>{user}</div>
          <div className='muiButtons'>
          <div className='homeMui tooltip'>
            <a href='https://metrics.logistics.coty.com' title='Home'>
              <HomeIcon />
            </a>
          </div>
          <div className='change tooltip'>
            <a href='https://forms.office.com/r/PqdttMbugi' target="_blank" rel="noopener noreferrer" title='ChangeRequest'>
              <ArticleIcon />
            </a>
          </div>
          <div className='contactUs' onClick={(e) => {window.location.href ='mailto:Logistics_Data@cotyinc.com?subject=KPI%20Metric%20Request';}} title='Contact Us'><HelpIcon/></div>



            </div>
          </div>
    </div>

  )
  
}

export default NavBar

