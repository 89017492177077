import React, { useState } from 'react'
import "./mobileHomeHero.css"; 
import TypingEffect from '../TypingEffect'  // Adjust the path based on your folder structure
import '../typingEffect.css';
import PlantList from '../PlantList';
import Americas from '../../img/Americas.png';
import APAC from '../../img/APAC.png';
import EMEA from '../../img/EMEA.png';
import CotyKPIS from '../cotyKPIS/CotyKPIS';
import axios from 'axios';
import {useQuery} from "@tanstack/react-query"





function MobileHomeHero() {
  //Posts and Gets ----------------------------------------------------------------------------------------------------------------------
//does auth need to be none for post options method as preflight does not contain credentials? 
    //Bring in Metrics from Database
    const getDbInfo = async() => {
      if(selectedPlant === null){
        console.log(selectedPlant)
        return null
      }
      else{
        //conditional statement to make sure plant has been selected by user
        const res = await axios.get(`https://api.${host}/db?plant=${selectedPlant}&date=${queryDate}`, {withCredentials: true});
        console.log(res)
        //to sort numerical and not lexicographical on 1st index of array
        setNewMetrics(res.data.sort((a,b)=>a[0]-b[0]))
        return res;
        
      } 

    }

  //Regional Call
  const getDbRegion = async() => {
    if(selectedRegion === null){
      console.log(selectedRegion)
      return null
    }
    else{
      //conditional statement to make sure plant has been selected by user
      const res = await axios.get(`https://api.${host}/dbregion?region=${selectedRegion}`, {withCredentials: true});
      console.log(res)
      //to sort numerical and not lexicographical on 1st index of array
      setRegionMetrics(res.data.sort((a,b)=>a[0]-b[0]))
      return res;
      
    } 

  }
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [currPlant, setCurrPlant] = useState(null)
  const [expandedMetric, setExpandedMetric] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const handlePlantSelect = (plantId) => {
    setSelectedRegion(null);
    setSelectedPlant(plantId);
    console.log("Calling get DB info")
    // You can perform other actions here as well, if needed
  }

  const handleRegionSelect = (region) => {
    setSelectedPlant(null);
    setSelectedRegion(region);
    console.log("Calling get DB Region")
    // You can perform other actions here as well, if needed
  }


  const resetSelectedPlant = () => {
    setSelectedPlant(null);
    setSelectedRegion(null);
  };
  
  axios.defaults.withCredentials = true
  //get environment
  const host = window.location.hostname
  const [newMetrics, setNewMetrics] = useState([])
  const [regionMetrics, setRegionMetrics] = useState([])

  const plantDictionary = {"JP04": "Kawasaki - Sankyu","Argent": "Argentina","CN03": "Wujiang - Boyol","CN10": "Shanghai - TingTong LX","DE20": "Weilbach","IT09": "Liscate","KSA": "KSA",
  "NA61": "3OVO","NA65": "DC 3PL Mexico","NA75": "North Brunswick","PL05": "Bielsko","Taiwan": "Taiwan - Focus","8002": "Brasil Goiania","8006": "Brasil Duque de Caxias","TH05": "Bangkok - LDS",
  "CN18": "Shanghai - Tingtong CB","AE02": "Dubai UAE","AE03": "JAFZA","AU10": "Sydney - Linfox CB/LX","ES09": "Barcelona","HK02": "Hong Kong - Bollore","MY01": "Malaysia Toll","NA29": "Riverside",
  "NA33": "Catano","SG13": "Singapore - Toll","DE21": "Dueren","UK27": "Bournemouth","NA53": "Sanford", "Korea": "Korea", "NA19": "Sanford-Factory", "NA21": "Hunt Valley - Factory", "C002": "Granollers - Factory",
  "CU05": "Ashford - Factory", "BRAF": "Goiania - Factory", "LF02": "Chartres - Factory", "MONF": "Monaco - Factory", "NA46": "West Rock"
};

const dbQuery = useQuery({
  queryKey: ["metrics", selectedPlant],
  queryFn: getDbInfo
})

const dbRegion = useQuery({
  queryKey: ["regMetrics", selectedRegion],
  queryFn: getDbRegion
})
 


  let metrics = {}
  let regMetrics = {}
    const date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear()
    let queryMonth = 0;
    if (month === 1){
        year = year-1
        queryMonth = 13
    }
    else if(month<11){
        queryMonth = "0" + (month-1).toString()
    }
    else{
        queryMonth = month-1
    }
    const queryDate = `${year}-${queryMonth}-01` 
    //const dateDict = {1: "December", 2: "January", 3: "February", 4: "March", 5: "April", 6: "May", 7: "June", 8: "July", 9: "August", 10: "September", 11: "October", 12: "November"}

    for(let element in newMetrics){
      let currentMetric = newMetrics[element]
    //have to make any undefined or null values an empty string so your input value in jsx isn't going from undefined to defined (causes controlled component errors)
      if(currentMetric[7] === undefined || currentMetric[7] === null){
        currentMetric[7] = ""
      }
      if(currentMetric[8] === undefined || currentMetric[8] === null){
        currentMetric[8] = ""
      }
      //goal
      if(currentMetric[13] === undefined || currentMetric[13] === null){
        currentMetric[13] = ""
      }
      //calctype
      if(currentMetric[19] === undefined || currentMetric[19] === null){
        currentMetric[19] = ""
      }
       //dividend
       if(currentMetric[20] === undefined || currentMetric[20] === null){
        currentMetric[20] = ""
      }
      //divisor
      if(currentMetric[21] === undefined || currentMetric[21] === null){
        currentMetric[21] = ""
      }
      //calculated
      if(currentMetric[22] === undefined || currentMetric[22] === null){
        currentMetric[22] = ""
      }
      //rcaRoot
      if(currentMetric[24] === undefined || currentMetric[24] === null){
        currentMetric[24] = ""
      }
      //rcaAction
      if(currentMetric[25] === undefined || currentMetric[25] === null){
        currentMetric[25] = ""
      }
      //site User
      if(currentMetric[27] === undefined || currentMetric[27] === null){
        currentMetric[27] = ""
      }
      //r12
      if(currentMetric[28] === undefined || currentMetric[28] === null){
        currentMetric[28] = ""
      }
      //null description
      if(currentMetric[6] === undefined || currentMetric[6] === null){
        currentMetric[6] = "NotRealMetric"
      }
      let key = currentMetric[6]
      //build main dictionary
      metrics[key] = {
        'order': currentMetric[0],
        'region': currentMetric[3],
        'plant': currentMetric[4],
        'plantName': currentMetric[5],
        'category': currentMetric[10],
        'description': currentMetric[6],
        'metric': currentMetric[7],
        'lastMetric': currentMetric[8],
        'adorn': currentMetric[12],
        'error': 'false',
        'help': " ",
        'info': `Data Source: ${currentMetric[15]} || Definition: ${currentMetric[16]}`,
        'rcaRoot': currentMetric[24],
        'rcaAction': currentMetric[25], 
        'goal': currentMetric[13],
        'siteUser': currentMetric[27],
        'r12m': currentMetric[28],
        'disabled': true
      }
      if(newMetrics[element][12] === '%'){
        if(newMetrics[element][7]!== ""){
          metrics[key].metric = (newMetrics[element][7]*100).toFixed(2)
        }
        if(newMetrics[element][8]!== ""){
          metrics[key].lastMetric = (newMetrics[element][8]*100).toFixed(2)
        }
        if(newMetrics[element][28]!== ""){
          metrics[key].r12m = (newMetrics[element][28]*100).toFixed(2)
        }
        if(newMetrics[element][13]!== ""){
          metrics[key].goal = (newMetrics[element][13]*100).toFixed(2)
        }
      }
    }
    let categories = {}
    console.log(metrics)

    if (Object.keys(metrics).length > 0) {
      for(let key in metrics) {
          let metric = metrics[key];
          if (!categories[metric.category]) {
            categories[metric.category] = {};
        }
        categories[metric.category][metric.description] = {'metric': metric.metric, 'lastMetric': metric.lastMetric, 'goal': metric.goal, 'r12m': metric.r12m}
          
          
      }
  }

  for(let element in regionMetrics){
    let currentMetric = regionMetrics[element]
  //have to make any undefined or null values an empty string so your input value in jsx isn't going from undefined to defined (causes controlled component errors)
    if(currentMetric[3] === undefined || currentMetric[3] === null){
      currentMetric[3] = ""
    }
    
    //null description
    if(currentMetric[0] === undefined || currentMetric[0] === null){
      currentMetric[0] = "NotRealMetric"
    }
    let key = currentMetric[0]
    //build main dictionary
    regMetrics[key] = {
    
      'category': currentMetric[1],
      'description': currentMetric[0],
      'metric': currentMetric[3],
      'adorn': currentMetric[2]
    }
    if(regionMetrics[element][2] === '%'){
      if(regionMetrics[element][3]!== ""){
        regMetrics[key].metric = (regionMetrics[element][3]*100).toFixed(2)
      }
    }
  }
  let regCategories = {}

  if (Object.keys(regMetrics).length > 0) {
    for(let key in regMetrics) {
        let regMetric = regMetrics[key];
        if (!regCategories[regMetric.category]) {
          regCategories[regMetric.category] = {};
      }
      regCategories[regMetric.category][regMetric.description] = {'metric': regMetric.metric}
        
        
    }
}

  
  

 
  

  
  console.log(categories)

  if(selectedPlant!==currPlant){
    setCurrPlant(selectedPlant)
  }

  if(dbQuery.isLoading) return <div className='loadText'>LOADING...</div>
  if(dbQuery.isError) return <div>Error processing data, please return to home page</div>

  if(dbRegion.isLoading) return <div className='loadText'>LOADING...</div>
  if(dbRegion.isError) return <div>Error processing data, please return to home page</div>



  return (
    <>
    <div className='hero toggleBack' >
      <div className='toggleBack' onClick={resetSelectedPlant}>
      <CotyKPIS/>

      </div>
      
        
        
    </div>

    <div className='hero' style={{ display: (selectedPlant || selectedRegion) ? 'none' : 'flex' }}>
        <div className='typeMessage'>
        <span className='place'>A Place To</span>
        <TypingEffect/>

        </div>
    
        
    </div>
    <h3 className='selectAPlant' style={{ display: (selectedPlant || selectedRegion) ? 'none' : 'flex' }}>View By Region</h3>
    <div className='imgFlex' style={{ display: (selectedPlant || selectedRegion) ? 'none' : 'flex' }}>
             <div className='region' onClick={() => handleRegionSelect('Americas')}>
             <img className='img' src={Americas} alt="Americas" />
             <h3>AMERICAS</h3>

             </div>
        

             <div className='region' onClick={() => handleRegionSelect('EMEA')}>
             <img className='img' src={EMEA} alt="EMEA" />
             <h3>EMEA</h3>

             </div>

             <div className='region' onClick={() => handleRegionSelect('APAC')}>
             <img className='img' src={APAC} alt="APAC" />
             <h3>APAC</h3>

             </div>
    
    

    </div>
    
    
  

    <h3 className='selectAPlant' style={{ display: (selectedPlant || selectedRegion) ? 'none' : 'flex' }}>View By Plant</h3>
    
    <div  className='hero'style={{ display: (selectedPlant || selectedRegion) ? 'none' : 'flex' }}>
        <PlantList onPlantClick={handlePlantSelect}/>

        </div>
    <div className='plantProfileShow' style={{ display: selectedPlant ? 'flex' : 'none' }}>
      <div className='plantTitleFlex'>
      <div className='plantItemHero'>
      {selectedPlant}
      </div>
      {selectedPlant && (
      <div className='plantNameProfile'>
        {plantDictionary[selectedPlant]}
      </div>
      )}
      </div>
      
      {selectedPlant && (
    <div className="plant-profile">
      {Object.entries(categories).map(([category, metrics]) => (
        <div key={category} className="category-section">
          <h3>{category}</h3>
          <div className="metrics-list">
            {Object.entries(metrics).map(([metricName, metricData]) => (
              <div key={metricName} className="metric-item" onClick={() => setExpandedMetric({ metricName, ...metricData })}>
                <span className="metric-value">{metricData.metric}</span> {/* Note the change here */}
                <span className="metric-name">{metricName} </span>
              </div>
            ))}
          </div>
        </div>
        
      ))}
      {expandedMetric && (
        <div className="overlay-mobile">
          <span className="close-mobile" onClick={() => setExpandedMetric(null)}>X</span>
          <div className="overlay-content-mobile">
            <span className="metric-value">{expandedMetric.metricName}</span>
            <span className="metric-value">THIS MONTH: {expandedMetric.metric}</span> 
            <span className="metric-value">
              GOAL: {expandedMetric.goal}{' '}
              {expandedMetric.metric > expandedMetric.goal ? (
                <span className="check-mark">✓</span>
              ) : (
                <span className="cross-mark">✗</span>
              )}
            </span>

            <span className="metric-value">
            LAST MONTH: {expandedMetric.lastMetric}
            { expandedMetric.lastMetric !== 0 && ( // Making sure we don't divide by zero
              <span className="percent-change">
                {(((expandedMetric.metric - expandedMetric.lastMetric) / expandedMetric.lastMetric) * 100).toFixed(2)}%
    </span>
  )}
</span>

            <span className="metric-value">12 MTH AVG: {expandedMetric.r12m}</span> 
          </div>
        </div>
      )}
    </div>
)}


    </div>


    <div className='plantProfileShow' style={{ display: selectedRegion ? 'flex' : 'none' }}>
      <div className='plantTitleFlex'>
      <div className='regionItemHero'>
      {selectedRegion === 'Americas' && (
        <div className='region regionProfile'>
          <img className='img' src={Americas} alt="Americas" />
        </div>
      )}
      {selectedRegion === 'EMEA' && (
        <div className='region regionProfile' >
          <img className='img' src={EMEA} alt="EMEA" />
        </div>
      )}
      {selectedRegion === 'APAC' && (
        <div className='region regionProfile' >
          <img className='img' src={APAC} alt="APAC" />
        </div>
      )}
      </div>
      {selectedRegion && (
      <div className='plantNameProfile'>
        {selectedRegion}
        
      </div>
      )}
      </div>
      
      {selectedRegion && (
    <div className="plant-profile">
      {Object.entries(regCategories).map(([category, metrics]) => (
        <div key={category} className="category-section">
          <h3>{category}</h3>
          <div className="metrics-list">
            {Object.entries(metrics).map(([metricName, metricData]) => (
              <div key={metricName} className="metric-item" onClick={() => setExpandedMetric({ metricName, ...metricData })}>
                <span className="metric-value">{metricData.metric}</span> {/* Note the change here */}
                <span className="metric-name">{metricName} </span>
              </div>
            ))}
          </div>
        </div>
        
      ))}
      {expandedMetric && (
        <div className="overlay-mobile">
          <span className="close-mobile" onClick={() => setExpandedMetric(null)}>X</span>
          <div className="overlay-content-mobile">
            <span className="metric-value">{expandedMetric.metricName}</span>
            <span className="metric-value">THIS MONTH: {expandedMetric.metric}</span> 
          </div>
        </div>
      )}
      
    </div>
)}


    </div>

  





        


    </>
    
    
  )
}

export default MobileHomeHero