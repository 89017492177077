import React from "react";
import "./cubeMobile.css";

const CubeMobile = ({ front, back, left, right, top, bottom }) => {
  return (
    <div className="side-cube-container-mobile">
      <div className="side-cube-mobile">
        <div className="side-cube-face-mobile side-cube-face-front-mobile">{front}</div>
        <div className="side-cube-face-mobile side-cube-face-back-mobile">{back}</div>
        <div className="side-cube-face-mobile side-cube-face-left-mobile">{left}</div>
        <div className="side-cube-face-mobile side-cube-face-right-mobile">{right}</div>
        <div className="side-cube-face-mobile side-cube-face-top-mobile">{top}</div>
        <div className="side-cube-face-mobile side-cube-face-bottom-mobile">{bottom}</div>
      </div>
    </div>
  );
};

export default CubeMobile;