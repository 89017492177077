import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {useQuery} from "@tanstack/react-query"
import { useReactToPrint } from 'react-to-print';
import './newResults.css'
import NewSideCube from '../Cube/NewSideCube';
import { Box } from "@mui/material";
import DownloadingIcon from '@mui/icons-material/Downloading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';




function NewResults({plant, countMetrics, countRCAs, access}) {
  const host = window.location.hostname
  //this data will be pulled from the backend with the corresponding metrics for each plant (assuming it will be returned as an array of objects)
  const [newMetrics, setNewMetrics] = useState([])

//keeps track of plant value to compare new value to
const [currPlant, setCurrPlant] = useState(plant)

const componentRef = useRef();










//date info
const date = new Date();
let month = date.getMonth() + 1;
let year = date.getFullYear()
let queryMonth = 0;
if (month === 1){
  year = year-1
  queryMonth = 12
}
else if(month<11){
  queryMonth = "0" + (month-1).toString()
}
else{
  queryMonth = month-1
}
const queryDate = `${year}-${queryMonth}-01`


const lowerGoals = []


const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: `${plant} ${queryDate} Report`,
  pageStyle: "@page { size: A1 landscape; }"
  //adjust pages per sheet
})

//post and gets 
//getting initial info
const getDbInfo2 = async() => {
  //conditional statement
    const res = await axios.get(`https://api.${host}/db?plant=${plant}&date=${queryDate}`, {withCredentials: true});
    console.log(res)
    setNewMetrics(res.data.sort())
    return res;
  
}

//db info to run again on component rerenders if plant value has changed
if(plant!==currPlant){
  getDbInfo2()
  setCurrPlant(plant)
}

//create metrics Dictionary critical it comes in with same format
let metrics = {}
let completedMetrics = 0
let count = 0
let x = 0
let metricDict = {}
metricDict["Missed Goals"] = []
metricDict["Missing RCAs"] = []
metricDict["Missing Metrics"] = []
metricDict["15% Deviation"] = []
//rcaCount is the rca count
let rcaCount = 0
for(let element in newMetrics){
  //have to make any undefined or null values an empty string so your input value in jsx isn't going from undefined to defined (causes controlled component errors)
  if(newMetrics[element][7] === undefined || newMetrics[element][7] === null){
    newMetrics[element][7] = ""
  }
  if(newMetrics[element][8] === undefined || newMetrics[element][8] === null){
    newMetrics[element][8] = ""
  }
  //goal
  if(newMetrics[element][13] === undefined || newMetrics[element][13] === null){
    newMetrics[element][13] = ""
  }
  //calctype
  if(newMetrics[element][19] === undefined || newMetrics[element][19] === null){
    newMetrics[element][19] = ""
  }
   //dividend
   if(newMetrics[element][20] === undefined || newMetrics[element][20] === null){
    newMetrics[element][20] = ""
  }
  //divisor
  if(newMetrics[element][21] === undefined || newMetrics[element][21] === null){
    newMetrics[element][21] = ""
  }
  //calculated
  if(newMetrics[element][22] === undefined || newMetrics[element][22] === null){
    newMetrics[element][22] = ""
  }
  //rcaRoot
  if(newMetrics[element][24] === undefined || newMetrics[element][24] === null){
    newMetrics[element][24] = ""
  }
  //rcaAction
  if(newMetrics[element][25] === undefined || newMetrics[element][25] === null){
    newMetrics[element][25] = ""
  }
  //site User
  if(newMetrics[element][27] === undefined || newMetrics[element][27] === null){
    newMetrics[element][27] = ""
  }
  //r12avg
  if(newMetrics[element][28] === undefined || newMetrics[element][28] === null){
    newMetrics[element][28] = ""
  }
  count+=1
  //build main dictionary
  metrics[element] = {
    'order': newMetrics[element][0],
    'plant': newMetrics[element][4],
    'plantName': newMetrics[element][5],
    'category': newMetrics[element][10],
    'description': newMetrics[element][6],
    'metric': newMetrics[element][7],
    'lastMetric': newMetrics[element][8],
    'adorn': newMetrics[element][12],
    'error': 'false',
    'help': " ",
    'rcaRoot': newMetrics[element][24],
    'rcaAction': newMetrics[element][25], 
    'goal': newMetrics[element][13],
    'siteUser': newMetrics[element][27],
    'r12m': newMetrics[element][28],
    'disabled': true
  }
  if(newMetrics[element][8]!== "" && newMetrics[element][8]<1 && newMetrics[element][8]>0){
    metrics[element].lastMetric = (newMetrics[element][8]).toFixed(4)
  }
   
 
  if(newMetrics[element][7].toString().length>0){
      completedMetrics+=1

  }
  else{
    if(Object.keys(metricDict).includes("Missing Metrics")){
      metricDict["Missing Metrics"].push(metrics[element].description)
  
    }
    else{
      metricDict["Missing Metrics"] = [metrics[element].description]
    }
    x+=1
  }
  let metricEl = parseFloat((newMetrics[element][7]))
  let metricGoal = parseFloat((newMetrics[element][13]))
  if(newMetrics[element][14] === 'Over'){
    lowerGoals.push(newMetrics[element][6])
    if(metricEl>metricGoal){
      if(Object.keys(metricDict).includes("Missed Goals")){
        metricDict["Missed Goals"].push(metrics[element].description)
    
      }
      else{
        metricDict["Missed Goals"] = [metrics[element].description]
      }
      if(newMetrics[element][24].length <1 || newMetrics[element][25].length <1){
        rcaCount+=1
        if(Object.keys(metricDict).includes("Missing RCAs")){
          metricDict["Missing RCAs"].push(metrics[element].description)
      
        }
        else{
          metricDict["Missing RCAs"] = [metrics[element].description]
        }
     }

    }

  }
  else if (metricEl<metricGoal){
    if(Object.keys(metricDict).includes("Missed Goals")){
      metricDict["Missed Goals"].push(metrics[element].description)
  
    }
    else{
      metricDict["Missed Goals"] = [metrics[element].description]
    }
   if(newMetrics[element][24].length <1 || newMetrics[element][25].length <1){
      rcaCount+=1
      if(Object.keys(metricDict).includes("Missing RCAs")){
        metricDict["Missing RCAs"].push(metrics[element].description)
    
      }
      else{
        metricDict["Missing RCAs"] = [metrics[element].description]
      }
   }

  }
  if (Math.abs(parseFloat(metrics[element].metric) - (parseFloat(metrics[element].r12m)))/(parseFloat(metrics[element].r12m)) >=0.15){
    if(Object.keys(metricDict).includes("15% Deviation")){
      metricDict["15% Deviation"].push(metrics[element].description)
  
    }
    else{
      metricDict["15% Deviation"] = [metrics[element].description]
    }
  }

 
  
}
let metricPercent = ((completedMetrics/count)*100).toFixed(2)

console.log(metricDict)

 //count of metrics
const [numOfMetrics, setNumOfMetrics] = useState(0)

//count of Rcas
const [numOfRcas, setNumOfRcas] = useState(0)


let y = numOfRcas

let z = numOfMetrics

 //keeps countMetrics from running on every render
useEffect(()=>{
  if(z !== x){
    setNumOfMetrics(x)
    countMetrics(x)
  } 

}, [x,z, countMetrics])

//keeps RCA count from running on every render
useEffect(()=>{
  if(y !== rcaCount){
    setNumOfRcas(rcaCount)
    countRCAs(rcaCount)
  } 

}, [y,rcaCount, countRCAs])

console.log(metricPercent)






const tableItems = Object.keys(metricDict).map((key, index) => {
  const items = metricDict[key].map((item, itemIndex) => (
    <li key={key + itemIndex}>{item}</li>
  ));

  return (
    <div key={key + index} className="section" >
      <h2>{key} - {metricDict[key].length}</h2>
      <ul>{items}</ul>
    </div>
  );
});












const dbQuery2 = useQuery({
  queryKey: ["metrics2"],
  queryFn: getDbInfo2
})

//Capture if query is loading and metrics should not render yet
if(dbQuery2.isLoading) return <div className='loadWalk'>
<NewSideCube
 front={
   <Box sx={{ p: 2 }}>
     <div className='sideCubeFont'>LOADING...</div>
     <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
   </Box>
 }
 back={
  <Box sx={{ p: 2 }}>
    <div className='sideCubeFont'>LOADING...</div>
    <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
  </Box>
}
 left={
   <Box sx={{ p: 2 }}>
     <div className='sideCubeFont'>LOADING...</div>
     <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
   </Box>
 }
 right={
   <Box sx={{ p: 2 }}>
     <div className='sideCubeFont'>LOADING...</div>
     <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
   </Box>
 }
 top={
  <Box sx={{ p: 2 }}>
    <div className='sideCubeFont'>LOADING...</div>
    <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
  </Box>
}
 bottom={
  <Box sx={{ p: 2 }}>
    <div className='sideCubeFont'>LOADING...</div>
    <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
  </Box>
}
/>
</div>
if(dbQuery2.isError) return <pre>Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a></pre>

    return (
        <div className='resultLoader'>
          <button className='print' onClick={handlePrint}>Print Report</button>
          <a href='https://app.powerbi.com/Redirect?action=OpenReport&appId=95f7c95b-56d9-4536-bd64-ba32e0f30820&reportObjectId=759cae80-3931-47a3-8136-8fb269c6d098&ctid=39eaae3c-87e6-48ce-8a26-c6c66ba780d6&reportPage=ReportSection819fd97c0814a567055b&pbi_source=appShareLink&portalSessionId=70d9caa2-7de4-4c88-9e89-15db8cf5576f'  target="_blank" and rel="noopener noreferrer" ><button className='dataViz'>Data Visuals</button></a>
          <div className='loadRes'  ref={componentRef}>
          <div className='header'><h4 style={{color: "red"}}>{plant} ATTENTION NEEDED ({queryDate}) </h4></div>

          <div className='flexDisp'>

              {tableItems}
            
             

          </div>
          

          </div>
          
          
        </div>
       
    
        )
  
}

export default NewResults