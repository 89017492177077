import React, { useState } from 'react'
import './plantOptions.css'
import axios from 'axios';
import {useQuery} from "@tanstack/react-query"
import DownloadingIcon from '@mui/icons-material/Downloading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NewSideCube from '../Cube/NewSideCube';
import { Box } from "@mui/material";



function PlantOptions({callBackPlant, plant, user}) {
  const host = window.location.hostname
  axios.defaults.withCredentials = true
  const [currPlantPick, setCurrPlantPick] = useState("")
  const [backEndPlants, setbackEndPlants] = useState([])

  const getPlantInfo = async() => {
    const res = await axios.get(`https://api.${host}/users?user=${user}`, {withCredentials: true});
    console.log(res)
    setbackEndPlants(res.data.sort())
    if(res.data.length > 0 && currPlantPick.length === 0) {
      callBackPlant(res.data[0][0])
      console.log(res.data[0][0])
    }
    return res;
  }
    //Updating Plant based on user selection in dropdown
    const updatePlant = (event) =>{
        callBackPlant(event.target.value)
        setCurrPlantPick(event.target.value)
    }

    
    const plantQuery = useQuery({
      queryKey: ["plants"],
      queryFn: getPlantInfo
    })

    
    
    if(plantQuery.isLoading) return <div className='load'>
    <NewSideCube
     front={
       <Box sx={{ p: 2 }}>
         <div className='sideCubeFont'>LOADING...</div>
         <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
       </Box>
     }
     back={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
     left={
       <Box sx={{ p: 2 }}>
         <div className='sideCubeFont'>LOADING...</div>
         <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
       </Box>
     }
     right={
       <Box sx={{ p: 2 }}>
         <div className='sideCubeFont'>LOADING...</div>
         <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
       </Box>
     }
     top={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
     bottom={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
   />
  </div>
    if(plantQuery.isError) return <pre>Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a> Contact us if issue persists</pre>
 

    if (backEndPlants.length === 0){
      return <h3>No plants associated with this user</h3>
    }
  return (
    <>
    <div className='plantComp'>
    <div className='plantOps'>
        <label className='labelOptions'>
        <select className='selectOptions' onChange={updatePlant}>
          <option value={backEndPlants[0]}>{backEndPlants[0]}</option>
        {backEndPlants.slice(1).map((plant, i)=>(
                            <option key = {i} value = {plant}>{plant}</option>
        
                        ))}

        </select>
        </label>
        
    </div>
    </div>
    
    
    </>
  )
}

export default PlantOptions