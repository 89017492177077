import React, { useState, useEffect, useMemo } from 'react';

function TypingEffect() {
  const words = useMemo(() => ["Analyze Your Data", "Optimize Decisions", "Monitor Metrics", "Learn"], []);
  const [wordIndex, setWordIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    const word = words[wordIndex];
    const typingSpeed = isDeleting ? 100 : 200;
    
    // Handle the end of a word
    if (!isDeleting && letterIndex === word.length) {
      setTimeout(() => setIsDeleting(true), 1000);
    } 
    // Handle the start of deleting a word
    else if (isDeleting && letterIndex === 0) {
      setIsDeleting(false);
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    } 
    // Handle deleting
    else if (isDeleting) {
      setTimeout(() => setLetterIndex(letterIndex - 1), typingSpeed);
    } 
    // Handle typing
    else {
      setTimeout(() => setLetterIndex(letterIndex + 1), typingSpeed);
    }
  }, [letterIndex, isDeleting, wordIndex, words]);

  return (
    <div className="typing-container">
      <span>{words[wordIndex].substring(0, letterIndex)}</span>
      <span className="cursor">|</span>
    </div>
  );
}

export default TypingEffect;

