import React, { useEffect, useState } from 'react'
import "./onePage.css"; 
import { XAxis, YAxis, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import DatasetIcon from '@mui/icons-material/Dataset';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Link } from 'react-router-dom';
import { Box } from "@mui/material";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import NewSideCube from '../Cube/NewSideCube';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import axios from 'axios';
import { useQuery } from "@tanstack/react-query"
import DownloadingIcon from '@mui/icons-material/Downloading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import InventoryIcon from '@mui/icons-material/Inventory';
import OutboundIcon from '@mui/icons-material/Outbound';
import WorkIcon from '@mui/icons-material/Work';
import CheckIcon from '@mui/icons-material/Check';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PublicIcon from '@mui/icons-material/Public';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

function OnePage({ plant }) {
  const host = window.location.hostname
  const [newMetrics, setNewMetrics] = useState([])
  const [currPlant, setCurrPlant] = useState(plant);
  const [currCategory, setCurrCategory] = useState("Overall");

  //function to update Category on user click/arrow click
  function updateCat(category) {
    let newCat = category.category
    setCurrCategory(newCat)
  }

  function updateOverallCat() {
    setCurrCategory("Overall")
  }

  //need dictionary for material ui icons
  const mui = {
    "Finance": <AttachMoneyIcon />, "HR": <PeopleIcon />, "HSE": <HealthAndSafetyIcon />, "Inventory": <InventoryIcon />, "Outbound": <OutboundIcon />,
    "Productivity": <WorkIcon />, "Quality": <CheckIcon />, "Safety": <SafetyCheckIcon />, "Service": <DesignServicesIcon />, "Sustainability": <PublicIcon />,
    "Transportation": <LocalShippingIcon />, "Controls": <ControlCameraIcon />, "Overall": <AllInclusiveIcon />
  }

  //stoplight
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colors = ['#AAFF00', '#FF7276', '#FFD580'];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 2000); // Change color every 2 seconds

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [colors.length]); // Include colors.length as a dependency

  //plant dictionary
  const plantDictionary = {
    "JP04": "Kawasaki - Sankyu", "Argent": "Argentina", "CN03": "Wujiang - Boyol", "CN10": "Shanghai - TingTong LX", "DE20": "Weilbach", "IT09": "Liscate", "KSA": "KSA",
    "NA61": "3OVO", "NA65": "DC 3PL Mexico", "NA75": "North Brunswick", "PL05": "Bielsko", "Taiwan": "Taiwan - Focus", "8002": "Brasil Goiania", "8006": "Brasil Duque de Caxias", "TH05": "Bangkok - LDS",
    "CN18": "Shanghai - Tingtong CB", "AE02": "Dubai UAE", "AE03": "JAFZA", "AU10": "Sydney - Linfox CB/LX", "ES09": "Barcelona", "HK02": "Hong Kong - Bollore", "MY01": "Malaysia Toll", "NA29": "Riverside",
    "NA33": "Catano", "SG13": "Singapore - Toll", "DE21": "Dueren", "UK27": "Bournemouth", "NA53": "Sanford", "Korea": "Korea", "NA19": "Sanford-Factory", "NA21": "Hunt Valley - Factory", "C002": "Granollers - Factory",
    "CU05": "Ashford - Factory", "BRAF": "Goiania - Factory", "LF02": "Chartres - Factory", "MC147": "Monaco - Factory", "NA46": "West Rock", "SA01": "Saudi Arabia"
  };

  //date info
  const date = new Date();
  let month = date.getMonth() + 1;
  let year = date.getFullYear()
  let queryMonth = 0;
  if (month === 1) {
    year = year - 1
    queryMonth = 12
  }
  else if (month < 11) {
    queryMonth = "0" + (month - 1).toString()
  }
  else {
    queryMonth = month - 1
  }
  const queryDate = `${year}-${queryMonth}-01`

  const dateDict = { 1: "December", 2: "January", 3: "February", 4: "March", 5: "April", 6: "May", 7: "June", 8: "July", 9: "August", 10: "September", 11: "October", 12: "November" }

  //post and gets 
  //getting initial info
  const getDbInfo3 = async () => {
    if (plant.length === 0) {
      return null
    }
    else {
      //conditional statement to make sure plant has been selected by user
      const res = await axios.get(`https://api.${host}/db?plant=${plant}&date=${queryDate}`, { withCredentials: true });
      //to sort numerical and not lexicographical on 1st index of array
      setNewMetrics(res.data.sort((a, b) => a[0] - b[0]))
      return res;
    }
  }

  const percentage = (value) => {
    const sign = value > 0 ? '+' : '';
    const formattedValue = value.toFixed(2);
    return value === Infinity || isNaN(value) ? '-' : `${sign}${formattedValue}%`;
  };

  //Tanstack Queries ----------------------------------------------------------------------------------------------------------------------
  const dbQuery3 = useQuery({
    queryKey: ["metricsA"],
    queryFn: getDbInfo3
  })

  //db info to run again on component rerenders if plant value has changed
  if (plant !== currPlant) {
    getDbInfo3()
    setCurrPlant(plant)
  }

  //lower goals array
  const lowerGoals = []

  //create metrics Dictionary critical it comes in with same format
  let metrics = {}
  let others = {}
  let categoryArray = []
  for (let element in newMetrics) {
    //have to make any undefined or null values an empty string so your input value in jsx isn't going from undefined to defined (causes controlled component errors)
    if (newMetrics[element][7] === undefined || newMetrics[element][7] === null) {
      newMetrics[element][7] = ""
    }
    if (newMetrics[element][8] === undefined || newMetrics[element][8] === null) {
      newMetrics[element][8] = ""
    }
    //goal
    if (newMetrics[element][13] === undefined || newMetrics[element][13] === null) {
      newMetrics[element][13] = ""
    }
    //calctype
    if (newMetrics[element][19] === undefined || newMetrics[element][19] === null) {
      newMetrics[element][19] = ""
    }
    //dividend
    if (newMetrics[element][20] === undefined || newMetrics[element][20] === null) {
      newMetrics[element][20] = ""
    }
    //divisor
    if (newMetrics[element][21] === undefined || newMetrics[element][21] === null) {
      newMetrics[element][21] = ""
    }
    //calculated
    if (newMetrics[element][22] === undefined || newMetrics[element][22] === null) {
      newMetrics[element][22] = ""
    }
    //rcaRoot
    if (newMetrics[element][24] === undefined || newMetrics[element][24] === null) {
      newMetrics[element][24] = ""
    }
    //rcaAction
    if (newMetrics[element][25] === undefined || newMetrics[element][25] === null) {
      newMetrics[element][25] = ""
    }
    //r12avg
    if (newMetrics[element][28] === undefined || newMetrics[element][28] === null) {
      newMetrics[element][28] = ""
    }
    if (newMetrics[element][12] === "%") {
      //build main dictionary
      let key = newMetrics[element][6]
      metrics[key] = {
        'order': newMetrics[element][0],
        'plant': newMetrics[element][4],
        'plantName': newMetrics[element][5],
        'category': newMetrics[element][10],
        'description': newMetrics[element][6],
        'metric': newMetrics[element][7],
        'lastMetric': newMetrics[element][8],
        'adorn': newMetrics[element][12],
        'error': 'false',
        'help': " ",
        'rcaRoot': newMetrics[element][24],
        'rcaAction': newMetrics[element][25],
        'goal': newMetrics[element][13],
        'r12m': newMetrics[element][28],
        'disabled': true
      }
      if (newMetrics[element][8] !== "" && newMetrics[element][8] < 1 && newMetrics[element][8] > 0) {
        metrics[key].lastMetric = (newMetrics[element][8]).toFixed(4)
      }
      if (newMetrics[element][12] === '%') {
        if (newMetrics[element][8] !== "") {
          metrics[key].lastMetric = (newMetrics[element][8] * 100).toFixed(2)
        }
        if (newMetrics[element][13] !== "") {
          metrics[key].goal = (newMetrics[element][13] * 100).toFixed(2)
        }
        if (newMetrics[element][7] !== "") {
          metrics[key].metric = (newMetrics[element][7] * 100).toFixed(2)
        }
        //need incoming data to be decimal format not percentage like what I'm sending back
        if (newMetrics[element][8] !== "") {
          metrics[key].lastMetric = (newMetrics[element][8] * 100).toFixed(2)
        }
        //changing the r12 percentages to whole numbers for comparison
        if (newMetrics[element][28] !== "") {
          metrics[key].r12m = (newMetrics[element][28] * 100).toFixed(2)
        }
      }
      //average so needs to be fixed to two decimals
      else if (newMetrics[element][12] !== '%' && newMetrics[element][12] !== 'Yes/No') {
        if (newMetrics[element][28] !== "") {
          let numNoCommas = (newMetrics[element][28]).toLocaleString('en-US', { style: 'decimal' })
          metrics[key].r12m = numNoCommas.replace(/\.\d*/, '')
        }
        if (newMetrics[element][7] !== "") {
          metrics[key].metric = (newMetrics[element][7]).toLocaleString('en-US', { style: 'decimal' });
        }
        if (newMetrics[element][13] !== "") {
          metrics[key].goal = (newMetrics[element][13]).toLocaleString('en-US', { style: 'decimal' });
        }
        if (newMetrics[element][8] !== "") {
          metrics[key].lastMetric = newMetrics[element][8].toLocaleString('en-US', { style: 'decimal' });
        }
      }
      //overunderarray
      if (newMetrics[element][14] === 'Over') {
        lowerGoals.push(newMetrics[element][6])
      }
    }
    let key = newMetrics[element][6]
    others[key] = {
      'order': newMetrics[element][0],
      'plant': newMetrics[element][4],
      'plantName': newMetrics[element][5],
      'category': newMetrics[element][10],
      'description': newMetrics[element][6],
      'metric': newMetrics[element][7],
      'lastMetric': newMetrics[element][8],
      'adorn': newMetrics[element][12],
      'error': 'false',
      'help': " ",
      'rcaRoot': newMetrics[element][24],
      'rcaAction': newMetrics[element][25],
      'goal': newMetrics[element][13],
      'r12m': newMetrics[element][28],
      'disabled': true
    }
    if (!categoryArray.includes(others[key].category)) {
      categoryArray.push(others[key].category);
    }
    if (newMetrics[element][8] !== "" && newMetrics[element][8] < 1 && newMetrics[element][8] > 0) {
      others[key].lastMetric = (newMetrics[element][8]).toFixed(4)
    }
    if (newMetrics[element][12] === '%') {
      if (newMetrics[element][8] !== "") {
        others[key].lastMetric = (newMetrics[element][8] * 100).toFixed(2)
      }
      if (newMetrics[element][13] !== "") {
        others[key].goal = (newMetrics[element][13] * 100).toFixed(2)
      }
      if (newMetrics[element][7] !== "") {
        others[key].metric = (newMetrics[element][7] * 100).toFixed(2)
      }
      //need incoming data to be decimal format not percentage like what I'm sending back
      if (newMetrics[element][8] !== "") {
        others[key].lastMetric = (newMetrics[element][8] * 100).toFixed(2)
      }
      //changing the r12 percentages to whole numbers for comparison
      if (newMetrics[element][28] !== "") {
        others[key].r12m = (newMetrics[element][28] * 100).toFixed(2)
      }
    }
    //average so needs to be fixed to two decimals
    else if (newMetrics[element][12] !== '%' && newMetrics[element][12] !== 'Yes/No') {
      if (newMetrics[element][28] !== "") {
        let numNoCommas = (newMetrics[element][28]).toLocaleString('en-US', { style: 'decimal' })
        others[key].r12m = numNoCommas.replace(/\.\d*/, '')
      }
      if (newMetrics[element][7] !== "") {
        others[key].metric = (newMetrics[element][7]).toLocaleString('en-US', { style: 'decimal' });
      }
      if (newMetrics[element][13] !== "") {
        others[key].goal = (newMetrics[element][13]).toLocaleString('en-US', { style: 'decimal' });
      }
      if (newMetrics[element][8] !== "") {
        others[key].lastMetric = newMetrics[element][8].toLocaleString('en-US', { style: 'decimal' });
      }
    }
    //overunderarray
    if (newMetrics[element][14] === 'Over') {
      lowerGoals.push(newMetrics[element][6])
    }
  }

  const [selectedMetric, setSelectedMetric] = useState(null);

  const data = [
    {
      name: selectedMetric,
      r12m: metrics[selectedMetric]?.r12m || 0,
      pMth: metrics[selectedMetric]?.lastMetric || 0,
      cMth: metrics[selectedMetric]?.metric || 0,
    }
  ];

  const parseNumber = (value) => {
    if (typeof value === 'string') {
      return Number(value.replace(/,/g, '')); // Remove commas and convert to number
    }
    return value;
  };

  const data2 = [
    {
      name: selectedMetric,
      r12m: others[selectedMetric]?.r12m !== undefined ? parseNumber(others[selectedMetric]?.r12m) || 0 : 0,
      pMth: others[selectedMetric]?.lastMetric !== undefined ? parseNumber(others[selectedMetric]?.lastMetric) || 0 : 0,
      cMth: others[selectedMetric]?.metric !== undefined ? parseNumber(others[selectedMetric]?.metric) || 0 : 0,
    }
  ];

  const handleHover = (key) => {
    setSelectedMetric(key);
  }

  const handleUnhover = () => {
    setSelectedMetric(null);
  }

  const percents = Object.keys(metrics).map(
    (key) => (
      <div key={key} className="flip-card">
        <div className="flip-card-inner" onMouseEnter={() => handleHover(key)} onMouseLeave={() => handleUnhover()}>
          <div className="flip-card-front">
            <h3 style={{
              color: lowerGoals.includes(key)
                ? (Number(metrics[key].metric) <= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) > Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
                : (Number(metrics[key].metric) >= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) < Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
            }}>{metrics[key].metric}%</h3>
            <h7 style={{
              color: lowerGoals.includes(key)
                ? (Number(metrics[key].metric) <= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) > Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
                : (Number(metrics[key].metric) >= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) < Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
            }}>{percentage(((metrics[key].metric - metrics[key].lastMetric) / metrics[key].lastMetric) * 100)}</h7>

            <h4 style={{
              color: lowerGoals.includes(key)
                ? (Number(metrics[key].metric) <= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) > Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
                : (Number(metrics[key].metric) >= Number(metrics[key].goal) ? '#AAFF00' :
                  Number(metrics[key].metric) < Number(metrics[key].goal) ? '#FF7276' :
                    Math.abs(Number(metrics[key].metric) - (Number(metrics[key].r12m))) / (Number(metrics[key].r12m)) >= 0.15 ? '#FFD580' :
                      'inherit')
            }}>{key}</h4>
          </div>
          <div className="flip-card-back">
            {window.innerWidth < 700 ? (
              <div>
                <h5>cMth: {metrics[key].metric}</h5><br />
                <h5>pMth: {metrics[key].lastMetric}</h5> <br />
                <h5>r12M: {metrics[key].r12m}</h5>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                  <XAxis dataKey="name" label={{ fill: 'white', fontSize: 'clamp(1rem, 0.7vw, 10rem)' }} tick={{ fill: '#ffffff', fontSize: 'clamp(1rem, 0.7vw, 10rem)' }} />
                  <YAxis tick={{ fill: 'white' }} />
                  <Legend />
                  <Bar dataKey="r12m" fill="#002554" />
                  <Bar dataKey="pMth" fill="#ad8b61" />
                  <Bar dataKey="cMth" fill="#fffef2" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    )
  );

  //other metrics
  const otherMets = Object.keys(others).map((key) => {
    if (others[key].category === currCategory) {
      return (
        <div key={key} className="flip-card">
          <div
            className="flip-card-inner"
            onMouseEnter={window.innerWidth <= 600 ? undefined : () => handleHover(key)}
            onMouseLeave={window.innerWidth <= 600 ? undefined : handleUnhover}
          >
            <div className="flip-card-front">
              <h3
                style={{
                  color:
                    lowerGoals.includes(key) ? (
                      Number(others[key].metric) <= Number(others[key].goal)
                        ? '#AAFF00'
                        : Number(others[key].metric) > Number(others[key].goal)
                          ? '#FF7276'
                          : Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15
                            ? '#FFD580'
                            : 'inherit'
                    ) : Number(others[key].metric) >= Number(others[key].goal) ? '#AAFF00' :
                      Number(others[key].metric) < Number(others[key].goal) ? '#FF7276' :
                        Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15 ? '#FFD580' :
                          'inherit',
                }}
              >
                {others[key].metric}
              </h3>
              <h7
                style={{
                  color:
                    lowerGoals.includes(key) ? (
                      Number(others[key].metric) <= Number(others[key].goal)
                        ? '#AAFF00'
                        : Number(others[key].metric) > Number(others[key].goal)
                          ? '#FF7276'
                          : Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15
                            ? '#FFD580'
                            : 'inherit'
                    ) : Number(others[key].metric) >= Number(others[key].goal) ? '#AAFF00' :
                      Number(others[key].metric) < Number(others[key].goal) ? '#FF7276' :
                        Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15 ? '#FFD580' :
                          'inherit',
                }}
              >
                {percentage(((Number(others[key].metric) - Number(others[key].lastMetric)) / Number(others[key].lastMetric)) * 100)}
              </h7>

              <h4
                style={{
                  color:
                    lowerGoals.includes(key) ? (
                      Number(others[key].metric) <= Number(others[key].goal)
                        ? '#AAFF00'
                        : Number(others[key].metric) > Number(others[key].goal)
                          ? '#FF7276'
                          : Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15
                            ? '#FFD580'
                            : 'inherit'
                    ) : Number(others[key].metric) >= Number(others[key].goal) ? '#AAFF00' :
                      Number(others[key].metric) < Number(others[key].goal) ? '#FF7276' :
                        Math.abs(Number(others[key].metric) - Number(others[key].r12m)) / Number(others[key].r12m) >= 0.15 ? '#FFD580' :
                          'inherit',
                }}
              >
                {key}
              </h4>
            </div>
            <div className="flip-card-back">
              {window.innerWidth < 700 ? (
                <div className='metricDataFlip'>
                  <h5>cMth: {others[key].metric}</h5><br />
                  <h5>pMth: {others[key].lastMetric}</h5> <br />
                  <h5>r12M: {others[key].r12m}</h5>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={data2}>
                    <XAxis dataKey="name" label={{ fill: 'white', fontSize: 'clamp(1rem, 0.7vw, 10rem)' }} tick={{ fill: '#ffffff', fontSize: 'clamp(1rem, 0.7vw, 10rem)' }} />
                    <YAxis tick={{ fill: 'white' }} />
                    <Legend />
                    <Bar dataKey="r12m" fill="#002554" />
                    <Bar dataKey="pMth" fill="#ad8b61" />
                    <Bar dataKey="cMth" fill="#fffef2" />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return null; // Skip rendering if the category doesn't match the current state variable
    }
  });

  if (dbQuery3.isLoading) return <NewSideCube
    front={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
    back={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
    left={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
    right={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
    top={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <DownloadingIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
    bottom={
      <Box sx={{ p: 2 }}>
        <div className='sideCubeFont'>LOADING...</div>
        <HourglassTopIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
      </Box>
    }
  />
  if (dbQuery3.isError) return <pre>Oops something went wrong please return to <a href='https://metrics.logistics.coty.com/'>homepage</a> Please Contact us if issue persists</pre>

  return (
    <div className='onePage'>

      <div className="labsNav">
        <div className="logoContain">

          <Link className='linkFlex' to="/">
            <h1>C O T Y</h1>
          </Link>
        </div>


        <div className='helpNav' >

          <div className='muiButtons'>
            <div className='homeMui'><a href='https://metrics.logistics.coty.com' title='Home'><HomeIcon /></a></div>
            <div className='change'><a href='https://forms.office.com/r/PqdttMbugi' target="_blank" rel="noopener noreferrer" title='ChangeRequest'><ArticleIcon /></a> </div>
            <div className='contactUs' onClick={(e) => { window.location.href = 'mailto:Logistics_Data@cotyinc.com?subject=KPI%20Metric%20Request'; }} title='Contact Us'><HelpIcon /></div>
          </div>
        </div>
      </div>

      <div className="KPI-container">
        <div className="stoplight-container">
          <div
            className="stoplight-light"
            style={{ backgroundColor: colors[currentColorIndex] }}
          >
            <div className="stoplight-label">
              {currentColorIndex === 0 && 'Hit Goal'}
              {currentColorIndex === 1 && 'Missed Goal'}
              {currentColorIndex === 2 && '15% Deviation'}
            </div>
          </div>
        </div>
        <div className="cubeLeftOne">
          <NewSideCube
            front={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Actionable Data</div>
                <DatasetIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
            back={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Fast Processing</div>
                <SpeedIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
            left={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Optimal Decisions</div>
                <PendingActionsIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
            right={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Increased Efficiency</div>
                <ThumbUpOffAltIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
            top={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Real Time Results</div>
                <AccessTimeIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
            bottom={
              <Box sx={{ p: 2 }}>
                <div className='sideCubeFont'>Easy to Use</div>
                <ElectricBoltIcon sx={{ fontSize: 'clamp(1rem, 1vw, 10rem)' }} />
              </Box>
            }
          />

        </div>

        <div className='plantSnap'>
          <h1>{plantDictionary[plant].toUpperCase()}</h1>
          <h4 className='dateFixOne'>{dateDict[month]} {year}</h4>
        </div>
        <div className='flexCatOne'>
          <div className='categoryOne' style={{ backgroundColor: currCategory === "Overall" ? '#fff' : 'rgba(0, 37, 84, 0.9)', color: currCategory === "Overall" ? '#002554' : 'white', border: currCategory === "Overall" ? '1px solid #ad8b61' : '1px solid #002554' }} onClick={() => updateOverallCat()} >
            {mui["Overall"]} <div className='overlay'>Overall</div>
          </div>
          {categoryArray.map((category, i) => (
            <div key={i} className='categoryOne' style={{ backgroundColor: currCategory === (category) ? '#fff' : 'rgba(0, 37, 84, 0.9)', color: currCategory === (category) ? '#002554' : 'white', border: currCategory === (category) ? '1px solid #ad8b61' : '1px solid #002554' }} onClick={() => updateCat({ category })}>
              {mui[category]} <div className='overlay'>{category}</div>
            </div>

          ))}

        </div>

        {currCategory === "Overall" && (
          <div className='flex'>
            {percents}
          </div>
        )}
        <div className='flex'>
          {otherMets}
        </div>

      </div>
    </div>
  )
}

export default OnePage
