import React, { useState } from 'react'
import './plantOptions.css'
import axios from 'axios';
import { useEffect } from 'react';



function PlantOptionsMobile({callBackPlant, user, mobile}) {
  const host = window.location.hostname
  axios.defaults.withCredentials = true
  const [backEndPlants, setbackEndPlants] = useState([])
  
    //Updating Plant based on user selection in dropdown
    const updatePlant = (event) =>{
        callBackPlant(event.target.value)
    }

    useEffect(()=>{
      const getPlantInfo2 = async() => {
        if (mobile === 'yes'){
          const res = await axios.get(`https://api.${host}/users?user=${user}`, {withCredentials: true});
        console.log(res)
        setbackEndPlants(res.data.sort())
        return res;
    
        }
        else{
          return 'not mobile';
        }
        
        
      }
      getPlantInfo2()
  
  
    }, [mobile, user, host])

    
    
    
   
 
  return (
    <>
    <div className='plantComp'>
    <div className='plantOps'>
        <label className='labelOptions'>
            PLANT
        <select className='selectOptions' onChange={updatePlant}>
          <option value = '-'></option>
        {backEndPlants.map((plant, i)=>(
                            <option key = {i} value = {plant}>{plant}</option>
        
                        ))}

        </select>
        </label>
        
    </div>
    </div>
    
    
    </>
  )
}

export default PlantOptionsMobile