import React from 'react'
import "./headerMobile.css"; 

function HeaderMobile() {
  return (
    <div className='headerMobileContainer'>
        <div className='headerMobileHamburger'>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
        <div className='headerMobileLogo'></div>
        <div className='mobileLogoTitle'>kpi</div>
        <span className='mobileMetric'>Metrics</span>
        <sup className="beta">βETA</sup>

    </div>
  )
}

export default HeaderMobile