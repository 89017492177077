import React, { useState } from 'react';
import SideBar from '../../components/sideBar/SideBar';
import OnePage from '../../components/OnePage/OnePage';
import Feature from '../../components/Feature/Feature';

function Main() {
  const [page, setPage] = React.useState('METRICS');
  const [plant, setPlant] = React.useState('');
  const [metricCount, setMetricCount] = React.useState(0);
  const [rcaCount, setRcaCount] = React.useState(0);
  const [isActive, setIsActive] = useState(false);

  const user = window.sessionStorage.getItem('user');
  const access = window.sessionStorage.getItem('access');
  const token = window.sessionStorage.getItem('token');

  const toggleButton = () => {
    setIsActive(!isActive);
  };

  return (
    <>
    <SideBar
              user={user}
              access={access}
              callBack={setPage}
              plantPick={setPlant}
              plant={plant}
              metricCount={metricCount}
              rcaCount={rcaCount}
            />
      <div className="metFlex">
        {!isActive && (
          <>  
            <Feature
              user={user}
              access={access}
              page={page}
              plant={plant}
              metricCounter={setMetricCount}
              rcaCounter={setRcaCount}
              token={token}
            />
          </>
        )}
      </div>
      {isActive ? (
        <button className="toggle-button" onClick={toggleButton} style={{ position: 'fixed', bottom: '20px', left: '2vw', backgroundColor: 'transparent' }}>
          Click to Edit
        </button>
      ) : (
        <button className="toggle-button" onClick={toggleButton} style={{ position: 'fixed', bottom: '0', left: '2vw', backgroundColor: '#002254' }}>
          View Results
        </button>
      )}
      <div>{isActive ? 
      <OnePage plant={plant} /> : null}</div>
    </>
  );
}

export default Main;

