import React from 'react';
import './plantList.css';

const PlantList = ({ onPlantClick }) => {
    const plantIds = [
        "JP04", "Argent", "CN03", "CN10", "DE20", "DE22", "IT09", "Korea", "KSA", "NA61",
        "NA65", "NA75", "PL05", "Taiwan", "8002", "8006", "TH05", "CN18", "AE02", "AE03",
        "AU10", "ES09", "HK02", "MY01", "NA29", "SG13", "DE21", "UK27", "NA53", "SA01",
        "NA19", "C002", "NA21", "CU05", "BRAF", "ID01"
      ];
       // hardcoded plant IDs

    return (
        <div className="plant-list">
            {plantIds.map(plantId => (
                <div 
                    className="plant-item" 
                    key={plantId}
                    onClick={() => onPlantClick(plantId)} // Trigger the callback when plant is clicked
                >
                    {plantId}
                </div>
            ))}
        </div>
    );
}

export default PlantList;

