import React from 'react'
import HeaderMobile from '../../components/headerMobile/HeaderMobile';
import MobileHomeHero from '../../components/mobileHomeHero/MobileHomeHero';
import "./KpiMobileBeta.css"; 

function KPIMobileBeta() {
  return (
    <div className='kpiPage'>
        <HeaderMobile/>
        <MobileHomeHero/>

    </div>

  )
}

export default KPIMobileBeta